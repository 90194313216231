import React, { useEffect, useMemo, useRef, useState } from 'react';

import { images, Logo, More, Notification } from 'assets/images/icons';
import { disabledInspect, CurrentDate } from 'utils/index';
import { Service } from 'config/service';

import {
	ClickAwayListener,
	Grid,
	Grow,
	IconButton,
	MenuList,
	Paper,
	Popper,
	Typography,
	withStyles,
	Badge,
	MenuItem,
	Hidden,
	Modal,
	Box,
	Tooltip,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';

// *Import Components
import Navigation from 'layouts/navigation';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { toast } from 'react-toastify';
import { socketConfig } from '../config/socket';
import { useStore } from '../context/AppContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Skeleton } from '@mui/material';

// *For Notification Badge
const NotificationBadge = withStyles({
	badge: {
		fontSize: '14px',
		backgroundColor: '#E82D2D',
		top: '-6px',
		right: '-5px',
		padding: '0 4px',
		fontWeight: 500,
	},
})(Badge);

var timer;
let socket = null;

function Header() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm();

	// *For Notification
	const [openNotification, setOpenNotification] = useState(false);
	const notifyDropdown = useRef(null);
	const [openModal, setOpenModal] = useState(false);
	const [notiDetail, setNotiDetail] = useState(null);

	// *For Notification Count
	const [notificationCount, setNotificationCount] = useState(0);
	const [notifications, setNotifications] = useState([]);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);

	// *For Daily Quote
	const [dailyQuote, setDailyQuote] = useState('');

	// *For Menu Drawer
	const [mobileMenu, setMobileMenu] = React.useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(anchorEl);
	const [notiActive, setNotiActive] = useState(null);
	const { authStore, paymentStore } = useStore();

	const menuToggle = () => {
		setMobileMenu(!mobileMenu);
	};

	// *Get Daily Quote
	const getDailyQuote = async () => {
		try {
			const token = localStorage.getItem('jwt');
			const { data } = await Service.getDailyQuote(token);
			setDailyQuote(data);
		} catch (error) {
			console.log('file: header.js => line 82 => getDailyQuote => error', error);
		}
	};

	// get noti unread count
	const getNotificationCount = async () => {
		try {
			const token = localStorage.getItem('jwt');
			const data = await Service.getUserNotificationUnreadCount(token);
			setNotificationCount(data.unread_count);
		} catch (error) {
			console.log(error);
		}
	};

	// *Get User Notifications
	const getUserNotifications = async (page, load_more = false) => {
		try {
			const token = localStorage.getItem('jwt');
			const { data, total } = await Service.getUserNotification(token, 10, page);
			if (load_more) {
				setNotifications([...notifications, ...data]);
			} else {
				setNotifications([...data]);
			}
			setTotal(total);
		} catch (error) {
			console.log('file: header.js => line 82 => getDailyQuote => error', error);
		}
	};

	const loadMoreNotifications = () => {
		setPage(page + 1);
		return getUserNotifications(page + 1, true);
	};

	// *For Open and Close Notification
	const notificationHandler = (type) => {
		if (type === true) {
			setOpenNotification((prev) => !prev);
		} else {
			setOpenNotification(false);
			setOpen(false);
			setNotifications([]);
			setTotal(0);
			setNotifications([]);
			setPage(1);
		}
	};

	// *Socket
	const connectSocket = () => {
		const token = localStorage.getItem('jwt');
		socket = socketConfig(token);

		if (socket?.disconnect) {
			clearTimeout(timer);
		}

		try {
			socket.connect();
			socket.on('connect', () => {
				console.log('Socket connected!'); // true
			});
		} catch (error) {
			console.log(error);
		}

		socket.on('messages', (args) => {
			getNotificationCount().then();
		});
		socket.on('connect_error', (err) => {
			socket.connect();
		});
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		setOpen((previousOpen) => !previousOpen);
		getUserNotifications(1, false).then();
	};

	useEffect(() => {
		getNotificationCount().then();
		getDailyQuote().then();
		disabledInspect();
		connectSocket();
		window.scrollTo({ top: 0 });
	}, []);

	const viewDetailNoti = async (type, id, notiId) => {
		try {
			if (type == 'reminder') {
				setNotiActive({
					type,
					id,
					notiId,
				});
				const token = localStorage.getItem('jwt');
				const { data } = await Service.getReminderDetail(token, id);
				setNotiDetail({
					listTitle: data?.subTask_id?.task_id?.title || '',
					subTitle: data?.subTask_id?.title || '',
					isCompleted: data?.subTask_id?.isCompleted || false,
					reminderAt: data?.reminderAt || '',
					color: data?.subTask_id?.task_id?.color?.code || '#ffffff',
					subTaskId: data?.subTask_id?._id,
				});
				setValue('title', data?.subTask_id?.title);
				setOpenModal(true);
				await Service.postReadNotification({ id: notiId }, token);
				getNotificationCount();
			}
		} catch (error) {
			setNotiDetail(null);
		}
	};

	const dateReminder = useMemo(() => {
		if (notiDetail) {
			const dateValue = moment(notiDetail.reminderAt);
			const formattedDate = {
				day: dateValue.date(),
				hour: dateValue.format('h').padStart(2, '0'),
				minute: dateValue.minute().toString().padStart(2, '0'),
				month: dateValue.month() + 1,
				year: dateValue.year(),
				ampm: dateValue.format('A'),
			};
			return formattedDate;
		}
	}, [notiDetail]);

	const taskComplete = async (status, subTaskId) => {
		try {
			const token = localStorage.getItem('jwt');
			const obj = {
				id: subTaskId,
				status,
			};
			const { message } = await Service.checkUncheckSubtask(obj, token);
			await viewDetailNoti(notiActive.type, notiActive.id, notiActive.notiId);
			toast.success(message, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',
				progress: undefined,
			});
		} catch (error) {
			toast.error(error, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',
				progress: undefined,
			});
		}
	};

	const handleClearNotifications = async () => {
		try {
			const token = localStorage.getItem('jwt');
			await Service.postClearNotifications(token);
			setNotifications([]);
			setTotal(0);
			setPage(1);
			setNotificationCount(0);
		} catch (error) {
			console.log('file: header.js => line 82 => getDailyQuote => error', error);
		}
	};

	return (
		<Grid
			className="header"
			container
			spacing={0}
			item
			xs={12}
			sm={12}
			md={12}
			lg={12}
			justifyContent="space-around"
			alignItems="center"
		>
			<Grid className="text-sm-center order-sm-2" item sm={12} md={8} lg={8}>
				<Hidden only={['md', 'lg', 'xl']}>
					<Typography component="h4">
						<CurrentDate />
					</Typography>
				</Hidden>
				<Typography component="h2">
					<span>Welcome </span>
					<span className="text-color">To Your New Day!</span>
				</Typography>
				<div className="quote">
					<Typography component="p">
						{dailyQuote[0]?.quote} - {dailyQuote[0]?.author}
					</Typography>
					<div className="sponsored">
						<Typography component="span">Sponsored by</Typography>
						<Typography
							className="link"
							component="span"
							onClick={() => window.open(`/${dailyQuote[0]?.sponsor}`, '_blank')}
						>
							{dailyQuote[0]?.sponsor}
						</Typography>
					</div>
				</div>
			</Grid>
			<Grid
				className="order-sm-1"
				container
				spacing={0}
				item
				sm={12}
				md={4}
				lg={4}
				justifyContent="space-between"
				alignItems="center"
			>
				<Hidden only={['md', 'lg', 'xl']}>
					<Grid item sm={2}>
						<IconButton className="menu-btn" size="medium" onClick={() => menuToggle()}>
							<Menu />
						</IconButton>
						{/* ========== Navigation ========== */}
						<Navigation open={mobileMenu} onClose={() => menuToggle()} />
					</Grid>
					<Grid className="text-sm-center" item sm={8}>
						<Logo />
					</Grid>
				</Hidden>
				<Grid className="text-right" item sm={2} md={12}>
					<Hidden only={['xs', 'sm']}>
						<Typography component="h4">
							<CurrentDate />
						</Typography>
					</Hidden>
					<IconButton
						className="notification"
						size="medium"
						ref={notifyDropdown}
						onClick={(e) => {
							handleClick(e);
						}}
					>
						<NotificationBadge badgeContent={notificationCount} color="secondary">
							<Notification />
						</NotificationBadge>
					</IconButton>
					<Popper
						open={open}
						anchorEl={notifyDropdown.current}
						className="dropdown dropdown-notification"
						transition
						disablePortal
						onClickAway={() => {
							notificationHandler(false);
						}}
					>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
								}}
							>
								<Paper>
									<ClickAwayListener
										onClickAway={() => {
											notificationHandler(false);
										}}
									>
										<MenuList autoFocusItem={openNotification} id="menu-list-grow">
											<div className="notify-header flex justify-items-end justify-between">
												<div className="flex items-center gap-2 text-start">
													<img
														src={images.notiIcon}
														alt="drag dot"
														width="23px"
														height="23px"
													/>
													<p className="notify-header-title">
														{`Notifications (${notificationCount > 100 ? '99+' : notificationCount || 0})`}{' '}
													</p>
												</div>
												<div className="flex justify-end w-full">
													<button
														onClick={handleClearNotifications}
														className="text-[#11335e] text-[12px] font-extrabold"
													>
														Clear
													</button>
												</div>
											</div>
											<div id="scrollableDiv" className="notify-wrapper">
												<InfiniteScroll
													dataLength={notifications.length}
													hasMore={notifications.length < total}
													scrollableTarget="scrollableDiv"
													loader={
														<Box padding={2} sx={{ pt: 0.5 }}>
															<Skeleton animation={false} />
															<Skeleton animation={false} />
															<Skeleton animation={false} />
														</Box>
													}
													next={loadMoreNotifications}
												>
													{notifications.map((notification, i) => (
														<MenuItem
															disabled={notification.isRead}
															key={i}
															onClick={() => {
																if (
																	authStore.user &&
																	authStore.isExpiredSubscription()
																) {
																	paymentStore.onShowMessageDialog();
																	return;
																}
																viewDetailNoti(
																	notification?.object_type,
																	notification?.object_id,
																	notification._id
																);
															}}
														>
															<div
																className={`noti-item ${
																	notification.unread == false
																		? 'noti-item-readed'
																		: ''
																}`}
															>
																<div className="noti-item-status">
																	{notification.unread == true ? (
																		<img
																			src={images.clockIconUnread}
																			alt="clock"
																			width="24px"
																			height="24px"
																		/>
																	) : (
																		<img
																			src={images.clockIcon}
																			alt="clock"
																			width="24px"
																			height="24px"
																		/>
																	)}
																</div>
																<div className="noti-item-container">
																	<div className="noti-item-title">
																		{notification.title}
																	</div>
																	<div className="noti-item-sub">
																		{notification.description}
																	</div>
																</div>
																<div className="noti-item-time">
																	{
																		moment(notification.createdAt)
																			.calendar()
																			.split(' at ')[0]
																	}
																</div>
															</div>
														</MenuItem>
													))}
												</InfiniteScroll>
											</div>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Grid>
			</Grid>
			<Modal
				open={openModal}
				onClose={() => setOpenModal(false)}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
				className="modal-reminder-detail"
			>
				<div className="modal-reminder-container">
					<div className="modal-reminder-header">
						<div className="modal-reminder-header-title">Reminder:</div>
						<div onClick={() => setOpenModal(false)}>
							<img src={images.closeIcon} alt="drag dot" width="24px" height="24px" />
						</div>
					</div>
					<div
						className={`${
							!notiDetail?.subTaskId && 'modal-reminder-content-nodata'
						} modal-reminder-content`}
					>
						{!notiDetail?.subTaskId ? (
							<div className="modal-reminder-nodata">No Data</div>
						) : (
							<div>
								<div>
									<Grid
										className="task-box task-box-info"
										item
										md={12}
										style={{ borderColor: notiDetail?.color }}
									>
										<div
											className="header mission-header"
											style={{ backgroundColor: notiDetail?.color }}
										>
											<div className="mission-header-container header-mission-container">
												<div item>
													<input
														className="input-task-title input-edit-title"
														placeholder="List Title..."
														{...register('listTitle', {
															required: 'Title is required',
														})}
														defaultValue={notiDetail?.listTitle}
														disabled
														id="input-list-edit"
													/>
												</div>
												<IconButton
													className="mission-header-icon-more"
													aria-label="menu"
													size="small"
												>
													<More />
												</IconButton>
											</div>
										</div>
										<div>
											<form className="sub-task-form sub-task-form-main sub-task-form-home">
												<div
													className="sub-task-container"
													onClick={() =>
														taskComplete(!notiDetail?.isCompleted, notiDetail.subTaskId)
													}
												>
													{notiDetail?.isCompleted == false ? (
														<div className="checkbox-round-task">
															<img
																src={images.checkBoxIcon}
																alt="checkBoxIcon"
																width="17px"
																height="17px"
															/>
														</div>
													) : (
														<div className="checkbox-round-task">
															<img
																src={images.checkedIcon}
																alt="checkedIcon"
																width="17px"
																height="17px"
															/>
														</div>
													)}
													<input
														className={`input-task-title sub-task-input ${
															notiDetail?.isCompleted == true && 'sub-task-input-complete'
														}`}
														placeholder="List Title..."
														{...register('title', {
															required: 'Title is required',
														})}
														defaultValue={notiDetail?.subTitle}
														value={notiDetail?.subTitle}
														readOnly
													/>
													<Tooltip
														title=""
														arrow
														placement="top"
														classes={{
															popper: 'tooltip-delete',
															popperArrow: 'tooltip-delete-popper-arrow',
														}}
													>
														<div
															className="sub-task-reminder"
															style={{ width: '16px', height: '16px' }}
															id="basic-menu"
														>
															<img
																src={images.reminderIcon}
																alt="drag dot"
																width="16px"
																height="16px"
															/>
														</div>
													</Tooltip>
												</div>
											</form>
										</div>
									</Grid>
									<div className="modal-reminder-footer-main">
										<div className="modal-reminder-footer">
											<div className="modal-reminder-footer-date">
												<img src={images.dateIcon} alt="drag dot" width="20px" height="20px" />
												{`${dateReminder?.day}/${dateReminder?.month}/${dateReminder?.year}`}
											</div>
											<div className="modal-reminder-footer-line" />
											<div className="modal-reminder-footer-date">
												<img
													src={images.reminderIcon}
													alt="drag dot"
													width="20px"
													height="20px"
												/>
												{`${dateReminder?.hour}:${dateReminder?.minute} ${
													dateReminder?.ampm || ''
												}`}
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						<div />
					</div>
				</div>
			</Modal>
		</Grid>
	);
}

export default Header;
