import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Menu, TextareaAutosize, Tooltip } from '@material-ui/core';
import { EditTimeIcon, images } from 'assets/images/icons';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { DtCalendar } from 'react-calendar-datetime-picker';
import 'react-calendar-datetime-picker/dist/style.css';

function SubTask(props) {
	const [disabledEdit, setDisabledEdit] = useState(true);
	const {
		taskComplete,
		disabled,
		prefix,
		elements,
		index,
		deleteSubTask,
		addReminderTask,
		editReminderTask,
		deleteReminderTask,
		editSubTask,
	} = props;
	const {
		register,
		handleSubmit,
		setFocus,
		formState: { errors },
		reset,
		setValue,
	} = useForm();
	const inputRef = useRef(null);
	const [date, setDate] = useState();
	const [reminderAt, setReminderAt] = useState();
	const [enableEdit, setEnableEdit] = useState(false);
	// *For Text Truncate
	const [textTruncate, setTextTruncate] = useState('');
	// Menu
	const [anchorEl, setAnchorEl] = useState(null);
	const divRef = useRef(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(divRef.current);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const popperProps = {
		disableInteractive: true,
		style: {
			transform: 'none',
		},
	};

	const parsedDate = (date) => {
		const dateValue = moment(date);
		const formattedDate = {
			day: dateValue.date(),
			hour: dateValue.format('h').padStart(2, '0'),
			minute: dateValue.minute().toString().padStart(2, '0'),
			month: dateValue.month() + 1,
			year: dateValue.year(),
			ampm: dateValue.format('A'),
		};
		return formattedDate;
	};

	const isSameDay = () => {
		const currentDate = new Date();
		return (
			currentDate.getFullYear() == date?.year &&
			currentDate.getMonth() + 1 == date?.month &&
			currentDate.getDate() == date?.day
		);
	};

	useEffect(() => {
		if (elements?.reminder?.reminderAt && new Date(elements?.reminder?.reminderAt) > new Date()) {
			setDate(parsedDate(new Date(elements?.reminder?.reminderAt)));
		} else {
			setDate(parsedDate(new Date()));
		}
		if (elements?.reminder?.reminderAt) {
			setReminderAt(parsedDate(new Date(elements?.reminder?.reminderAt)));
		} else {
			setReminderAt(parsedDate(new Date()));
		}
	}, [elements]);

	React.useEffect(() => {
		if (!disabledEdit) {
			setFocus('title');
		}
	}, [disabledEdit]);

	// *For Text Truncate
	const truncateHandler = (id) => {
		try {
			if (id === textTruncate) {
				setTextTruncate('');
			} else {
				setTextTruncate(id);
			}
		} catch (error) {
			console.log('file: ListItem.js => line 36 => truncateHandler => error', error);
		}
	};

	const addList = async (e) => {
		await editSubTask(elements._id, { title: e.target.value, task_id: elements.task_id });
		setDisabledEdit(true);
	};

	const enterHandler = async (e) => {
		try {
			if (e.key === 'Enter') {
				inputRef.current.blur();
			}
		} catch (error) {
			console.log('file: ListItem.js => line 170 => enterHandler => error', error);
		}
	};

	const handleKeyDown = (e) => {
		if (
			!/[0-9]/.test(e.key) &&
			e.key !== 'Backspace' &&
			e.key !== 'ArrowLeft' &&
			e.key !== 'ArrowRight' &&
			e.key !== 'Tab' &&
			e.key !== 'Delete'
		) {
			e.preventDefault();
		}
	};

	return (
		<Droppable droppableId={`${prefix}`}>
			{(provided) => (
				<div style={{ width: '100%', minHeight: '10px' }} {...provided.droppableProps} ref={provided.innerRef}>
					<Draggable draggableId={elements._id} index={index}>
						{(provided) => (
							<div ref={provided.innerRef} {...provided.draggableProps}>
								<div className="sub-task-form" {...provided.dragHandleProps} ref={divRef}>
									<div
										className="sub-task-drag cursor-pointer"
										style={{ width: '16px', height: '16px' }}
										{...provided.dragHandleProps}
									>
										<img src={images.dragIcon} alt="drag dot" width="12px" height="12px" />
									</div>
									<div className="sub-task-container cursor-pointer">
										{elements.isCompleted === false ? (
											<div
												onClick={() => taskComplete(elements._id, elements.isCompleted)}
												className="checkbox-round-task"
											>
												<img
													src={images.checkBoxIcon}
													alt="checkBoxIcon"
													width="17px"
													height="17px"
												/>
											</div>
										) : (
											<div
												onClick={() => taskComplete(elements._id, elements.isCompleted)}
												className="checkbox-round-task"
											>
												<img
													src={images.checkedIcon}
													alt="checkedIcon"
													width="17px"
													height="17px"
												/>
											</div>
										)}
										{disabledEdit ? (
											<div
												{...provided.dragHandleProps}
												onClick={() => {
													setDisabledEdit(false);
													setFocus('title');
												}}
												className={`sub-task-display ${
													elements.isCompleted == true && 'sub-task-display-complete'
												}`}
											>
												{elements.title}
											</div>
										) : (
											<TextareaAutosize
												className={`input-task-title sub-task-input ${
													elements.isCompleted == true && 'sub-task-input-complete'
												}`}
												placeholder="List Title..."
												{...register('title', {
													required: 'Title is required',
												})}
												defaultValue={elements.title}
												readOnly={disabledEdit}
												onClick={() => setDisabledEdit(false)}
												onKeyPress={(e) => {
													enterHandler(e);
												}}
												onBlur={(e) => {
													addList(e);
												}}
												ref={inputRef}
											/>
										)}
										{!elements?.reminder?._id ? (
											<Tooltip
												title="Set Reminder"
												arrow
												placement="top"
												classes={{
													popper: 'tooltip-delete',
													popperArrow: 'tooltip-delete-popper-arrow',
												}}
												interactive
											>
												<div
													onClick={handleClick}
													className="sub-task-reminder sub-task-reminder-overdue"
													style={{ width: '16px', height: '16px' }}
													{...provided.dragHandleProps}
													id="basic-menu"
												>
													<img
														src={images.reminderIcon}
														alt="drag dot"
														width="16px"
														height="16px"
													/>
												</div>
											</Tooltip>
										) : elements?.reminder.reminderAt < moment().valueOf() ? (
											// after remindered
											<Tooltip
												interactive
												PopperProps={popperProps}
												title={
													<div className="tooltip-overdue-contaier">
														<div className="tooltip-overdue-wrapper">
															<div className="tooltip-overdue-item">
																<img
																	src={images.clockRedIcon}
																	alt="drag dot"
																	width="14px"
																	height="14px"
																/>
																{`${reminderAt?.day}/${reminderAt?.month}/${reminderAt?.year}`}
															</div>
															<div className="tooltip-overdue-line" />
															<div className="tooltip-overdue-item">
																<img
																	src={images.notiRedIcon}
																	alt="drag dot"
																	width="14px"
																	height="14px"
																/>
																{`${reminderAt?.hour}:${reminderAt?.minute} ${reminderAt?.ampm || ''}`}
															</div>
														</div>
														<div className="cursor-pointer" onClick={handleClick}>
															<img
																src={images.editTimeIcon}
																alt="drag dot"
																width="24px"
																height="24px"
															/>
														</div>
													</div>
												}
												placement="right"
												classes={{
													popper: 'tooltip-overdue',
												}}
											>
												<div
													className="sub-task-reminder sub-task-reminder-overdue"
													style={{ width: '16px', height: '16px' }}
													{...provided.dragHandleProps}
													id="basic-menu"
												>
													<img
														src={images.overdueIcon}
														alt="drag dot"
														width="16px"
														height="16px"
													/>
												</div>
											</Tooltip>
										) : (
											// remindered
											<Tooltip
												interactive
												title={
													<div className="tooltip-overdue-contaier">
														<div className="tooltip-overdue-wrapper">
															<div className="tooltip-overdue-item">
																<img
																	src={images.clockReminderIcon}
																	alt="drag dot"
																	width="14px"
																	height="14px"
																/>
																{`${reminderAt?.day}/${reminderAt?.month}/${reminderAt?.year}`}
															</div>
															<div className="tooltip-overdue-line" />
															<div className="tooltip-overdue-item">
																<img
																	src={images.notiReminderIcon}
																	alt="drag dot"
																	width="14px"
																	height="14px"
																/>
																{`${reminderAt?.hour}:${reminderAt?.minute} ${reminderAt?.ampm || ''}`}
															</div>
														</div>
														<Tooltip
															interactive
															title="Edit"
															arrow
															placement="top"
															classes={{
																popper: 'tooltip-delete',
																popperArrow: 'tooltip-delete-popper-arrow',
															}}
														>
															<div
																className="btn-tooltip-edit-reminder"
																onClick={(e) => {
																	handleClick(e);
																	setEnableEdit(true);
																}}
															>
																<EditTimeIcon />
															</div>
														</Tooltip>
													</div>
												}
												placement="right"
												classes={{
													popper: 'tooltip-delete tooltip-remindered',
												}}
											>
												<div
													className="sub-task-reminder sub-task-reminder-overdue"
													style={{ width: '16px', height: '16px' }}
													{...provided.dragHandleProps}
													id="basic-menu"
												>
													<img
														src={images.reminderedIcon}
														alt="drag dot"
														width="16px"
														height="16px"
													/>
												</div>
											</Tooltip>
										)}
										{/*<Tooltip*/}
										{/*	title="Delete"*/}
										{/*	arrow*/}
										{/*	placement="top"*/}
										{/*	classes={{*/}
										{/*		popper: 'tooltip-delete',*/}
										{/*		popperArrow: 'tooltip-delete-popper-arrow',*/}
										{/*	}}*/}
										{/*>*/}
										{/*	<div*/}
										{/*		className="sub-task-delete"*/}
										{/*		style={{ width: '16px', height: '16px' }}*/}
										{/*		{...provided.dragHandleProps}*/}
										{/*		// onClick={() => deleteSubTask(elements._id)}*/}
										{/*	>*/}
										{/*		<img*/}
										{/*			src={images.deleteBlackIcon}*/}
										{/*			alt="drag dot"*/}
										{/*			width="16px"*/}
										{/*			height="16px"*/}
										{/*		/>*/}
										{/*	</div>*/}
										{/*</Tooltip>*/}
										<Menu
											id="menu-date-picker"
											anchorEl={anchorEl}
											open={open}
											interactive
											onClose={(e) => {
												handleClose(e);
												setEnableEdit(false);
											}}
											disablePortal
											MenuListProps={{
												'aria-labelledby': 'basic-button',
											}}
											classes={{
												root: 'menu-date-picker',
												list: 'menu-date-picker-list',
											}}
											anchorOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'left',
											}}
										>
											<div className="menu-date-picker-wrapper">
												<div className="menu-date-picker-header">
													<div className="menu-date-picker-title">
														Set Reminder for this task
													</div>
													<img
														onClick={() => handleClose()}
														src={images.closeIcon}
														alt="drag dot"
														width="24px"
														height="24px"
													/>
												</div>
												<DtCalendar
													onChange={(value) => {
														setDate(value);
													}}
													autoClose={false}
													NextBtnIcon={() => (
														<img
															src={images.rightIcon}
															alt="drag dot"
															width="24px"
															height="24px"
														/>
													)}
													PreviousBtnIcon={() => (
														<img
															src={images.leftIcon}
															alt="drag dot"
															width="24px"
															height="24px"
														/>
													)}
													type="single"
													local="en"
													initValue={date}
													minDate={{
														year: moment().year(),
														month: moment().month() + 1,
														day: moment().date(),
													}}
												/>
												<div className="select-time-reminder-title">Select time</div>
												<div className="select-time-reminder-wrapper">
													<div className="select-time-reminder-group">
														<div className="select-time-reminder-item">
															<input
																defaultValue={date?.hour}
																value={date?.hour}
																className="select-time-reminder"
																type="number"
																id="quantity"
																// min={isSameDay() ? date.hour : 0}
																min={0}
																max={11}
																onChange={(e) => {
																	let { value } = e.target;
																	if (value.toString().length > 2) {
																		value = value.replace(/^0+(?!$)/, '');
																	}
																	if (value > 11) {
																		value = 11;
																	}
																	if (value.toString().length === 1) {
																		value = value.toString().padStart(2, '0');
																	}
																	setDate((pre) => ({
																		...pre,
																		hour: isNaN(value) ? '' : value,
																	}));
																}}
																onKeyDown={handleKeyDown}
															/>
															<p>Hour</p>
														</div>
														<div className="select-time-reminder-dot">:</div>
														<div className="select-time-reminder-item">
															<input
																defaultValue={date?.minute}
																value={date?.minute}
																className="select-time-reminder"
																type="number"
																id="quantity"
																// min="0"
																max={59}
																onChange={(e) => {
																	let { value } = e.target;
																	if (value.toString().length > 2) {
																		value = value.replace(/^0+(?!$)/, '');
																	}
																	if (value > 59) {
																		value = 59;
																	}
																	if (value.toString().length === 1) {
																		value = value.toString().padStart(2, '0');
																	}
																	setDate((pre) => ({
																		...pre,
																		minute: isNaN(value) ? '' : value,
																	}));
																}}
																onKeyDown={handleKeyDown}
																min={0}
																maxLength={2}
																// min={
																//   isSameDay() && date.hour == new Date().getHours()
																//     ? new Date().getMinutes()
																//     : 0
																// }
															/>
															<p>Minute</p>
														</div>
													</div>
													<div>
														<div className="select-time-reminder-action-group">
															<button
																onClick={() =>
																	setDate((prev) => ({ ...prev, ampm: 'AM' }))
																}
																className={`select-time-reminder-btn ${
																	date?.ampm == 'AM' &&
																	'select-time-reminder-btn-selected'
																}`}
															>
																AM
															</button>
															<button
																onClick={() =>
																	setDate((prev) => ({ ...prev, ampm: 'PM' }))
																}
																className={`select-time-reminder-btn ${
																	date?.ampm == 'PM' &&
																	'select-time-reminder-btn-selected'
																}`}
															>
																PM
															</button>
														</div>
													</div>
												</div>
												{!elements?.reminder?._id ? (
													<button
														className="menu-date-picker-btn"
														onClick={() => {
															handleClose();
															addReminderTask(date, elements._id);
														}}
													>
														Set Reminder
													</button>
												) : (
													<div className="menu-date-picker-action-group">
														<button
															className="menu-date-picker-btn menu-date-picker-btn-edit"
															onClick={() => {
																handleClose();
																deleteReminderTask(elements?.reminder?._id);
															}}
														>
															Remove
														</button>
														<button
															className="menu-date-picker-btn menu-date-picker-btn-edit"
															onClick={() => {
																handleClose();
																editReminderTask(date, elements?.reminder?._id);
															}}
														>
															Save
														</button>
													</div>
												)}
											</div>
										</Menu>
									</div>
								</div>
							</div>
						)}
					</Draggable>
				</div>
			)}
		</Droppable>
	);
}

export default SubTask;
